import { css } from '@emotion/core';

export const breakpoints = {
  small: 576,
  medium: 768,
  large: 1000,
  xlarge: 1400,
};

export const mq = {
  small: `@media(min-width: ${breakpoints.small}px)`,
  medium: `@media(min-width: ${breakpoints.medium}px)`,
  large: `@media(min-width: ${breakpoints.large}px)`,
  xlarge: `@media(min-width: ${breakpoints.xlarge}px)`,
  ie: `@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)`,
};

export const hideSmall = css`
  display: none;
  ${mq.small} {
    display: inherit;
  }
`;

export const showSmall = css`
  display: inherit;
  ${mq.small} {
    display: none;
  }
`;
