import React from 'react';
import { event, VIEWPORT, getViewPort } from './event';

export default class extends React.Component {
  state = {
    inViewport: false,
    viewport: getViewPort(),
  };

  static defaultProps = {
    offset: 20,
  };

  componentDidMount() {
    event.addListener(VIEWPORT, this.onViewportChange, this);
    this.onViewportChange(getViewPort());
  }

  componentWillUnmount() {
    event.removeListener(VIEWPORT, this.onViewportChange);
  }

  onViewportChange = viewport => {
    const state = { viewport };
    const { offset } = this.props;
    if (this.ref) {
      const { top, bottom } = this.ref.getBoundingClientRect();
      state.inViewport = top > offset && bottom < viewport.height - offset;
    }
    this.setState(state);
  };

  scrollRef = ref => {
    this.ref = ref;
  };

  render() {
    const { children } = this.props;
    const { inViewport, viewport } = this.state;
    return children({
      scrollRef: this.scrollRef,
      inViewport,
      viewport,
    });
  }
}
