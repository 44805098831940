/* global THREE */
import React from 'react';
import styled from '@emotion/styled';
import throttle from 'lodash.throttle';
import sky from './sky4_s.jpg';
import skyBig from './sky4.jpg';
import Layer from '../Layer';
import window from '../../utils/window';
import { mq, breakpoints } from '../../utils/mq';

require('three');
require('three/examples/js/renderers/Projector');

const Content = styled(Layer)`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
`;

const Container = styled.div`
  background: url(${sky}) center;
  background-size: cover;
  position: relative;
  color: white;
  width: 100vw;
  height: 450px;
  ${mq.small} {
    height: 100vh;
  }
`;

export default class Scene extends React.Component {
  constructor() {
    super();
    this.aspect = 1.8;
    this.onResize = throttle(this.onResize, 200);
  }

  size = () => {
    const width = window.innerWidth;
    const height =
      window.innerWidth > breakpoints.small ? window.innerHeight : 450;
    return { width, height };
  };

  componentDidMount() {
    const { width, height } = this.size();
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.domElement.style.opacity = 0;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.r.appendChild(this.renderer.domElement);
    this.camera = this.initCamera(width, height);
    this.initScene(width, height);
  }

  onAnimate = () => {
    this.camera.position.x += 0.00015;
    this.camera.position.y += 0.00015;
    this.camera.lookAt(this.scene.position);
    this.group.rotation.x += 0.00015;
    this.group.rotation.y += 0.00015;
    this.renderer.render(this.scene, this.camera);
    window.requestAnimationFrame(this.onAnimate);
  };

  onResize = () => {
    const { width, height } = this.size();
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    if (this.bgHeight) {
      this.onBGResize(width, height);
    }
  };

  initScene(width, height) {
    this.scene = new THREE.Scene();
    this.bgTexture = new THREE.TextureLoader().load(skyBig, texture => {
      this.scene.background = this.bgTexture;
      this.renderer.domElement.style.opacity = 1;
      this.bgWidth = texture.image.width;
      this.bgHeight = texture.image.height;
      this.onBGResize(width, height);
      this.group = this.initParticles();
      this.scene.add(this.group);

      window.addEventListener('resize', this.onResize, false);

      this.onAnimate();
    });
  }

  initCamera = (width, height) => {
    const camera = new THREE.PerspectiveCamera(75, width / height, 1, 3000);
    camera.position.z = 1000;
    return camera;
  };

  initParticles() {
    const group = new THREE.Group();
    const material = new THREE.SpriteMaterial({
      map: new THREE.CanvasTexture(this.generateSprite()),
      blending: THREE.AdditiveBlending,
    });
    const radius = 2500;
    for (let i = 0; i < 1000; i++) {
      const particle = new THREE.Sprite(material);
      particle.position.x = Math.random() * radius - radius / 2;
      particle.position.y = Math.random() * radius - radius / 2;
      particle.position.z = Math.random() * radius - radius / 2;
      particle.scale.x = particle.scale.y = Math.random() * 20 + 15;
      group.add(particle);
    }
    return group;
  }

  onBGResize = (width, height) => {
    const containerRatio = width / height;
    const imgRatio = this.bgWidth / this.bgHeight;
    let finalHeight;
    let finalWidth;
    if (containerRatio < imgRatio) {
      finalHeight = height;
      finalWidth = height * imgRatio;
    } else {
      finalWidth = width;
      finalHeight = width / imgRatio;
    }
    this.bgTexture.repeat = new THREE.Vector2(
      width / finalWidth,
      height / finalHeight,
    );
    this.bgTexture.offset.x = ((this.bgTexture.repeat.x - 1) / 2) * -1;
    this.bgTexture.offset.y = ((this.bgTexture.repeat.y - 1) / 2) * -1;
  };

  generateSprite = () => {
    const canvas = window.document.createElement('canvas');
    canvas.width = 32;
    canvas.height = 32;
    const context = canvas.getContext('2d');
    const gradient = context.createRadialGradient(
      canvas.width / 2,
      canvas.height / 2,
      0,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2,
    );
    gradient.addColorStop(0, 'rgba(255,255,255,1)');
    gradient.addColorStop(0.15, 'rgba(150,150,80,1)');
    gradient.addColorStop(0.3, 'rgba(70,25,0,1)');
    gradient.addColorStop(1, 'rgba(0,0,0,1)');
    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);
    return canvas;
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { children } = this.props;
    return (
      <Container>
        <div style={{ display: 'none' }}>
          <canvas
            ref={r => {
              this.sprite = r;
            }}
          />
        </div>
        <div
          ref={r => {
            this.r = r;
          }}
        />
        <Content>{children}</Content>
      </Container>
    );
  }
}
