import React from 'react';
import styled from '@emotion/styled';
import { Motion, spring } from 'react-motion';
import WithScroll from '../utils/scroll/WithScroll';
import asHoc from '../utils/asHoc';

export const ProfileContour = ({ className, style, anim }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    viewBox="0 0 1085.71 1021.12"
  >
    <path
      d="M-412,600.54a243.3,243.3,0,0,1,47.58-27.6c18.76-8.15,25.95-8.33,41.4-17,16-9,23.53-17.32,38.21-30.79,11.2-10.28,34.41-31.57,59.45-47.77,19-12.29,41-20.93,84.93-38.22,18.46-7.26,36.16-16.7,55.2-22.29,15.19-4.46,23.07-5.45,24.41-10.61,3-11.49-32.75-19-48.83-52-.43-.89-3.48-15.47-9.55-44.59-6.23-29.84-13.39-38.1-9.25-53.67,4.31-16.19,6.8-15.69-7.28-24.54-41.51-26.11-39.84-37.66-46.21-50.5-8.16-16.43-6.9-32-12.63-49.63-13.57-41.77-16.72-66.32-22.3-89.17-6.31-25.87-36.93-67-44.93-111.53-15.06-71.69-27-79.74-1.78-143.25,13-32.7,33.16-56,44.59-69,6.45-7.34,29.22-32.48,76.43-61.57,37.07-22.85,83.7-51.58,151.81-61.57,47.06-6.91,106.3-15.61,146.5,13.8,18.32,13.4,35.74,36.95,66.87,46.71,5.45,1.7,16,4.46,24.42,13.8,8.66,9.61,11.61,22.38,13.8,31.84,3,12.9.89,13.62,3.19,22.3,4.34,16.41,14.17,22.61,25.47,37.15,12,15.46,17.43,29.66,22.3,42.46,11.22,29.52,10.88,55.24,10.61,75.38-.43,32.47-.64,48.7-6.37,64.75-4.87,13.66-4.66,20.08-4.24,32.91.4,12.39,2.82,14.41,4.24,14.86,1.9.61,3.81-1,13.8-9.55,9.38-8,11-9.45,13.8-9.56,5.41-.2,10.38,4.5,12.74,8.5,3,5,1.39,8.2,3.19,17,1.44,7.05,3.56,10.29,5.3,14.86,3.35,8.76,2.5,14.91,1.07,37.16-1.83,28.27-.66,21.53-2.13,39.28-1.86,22.56-2.86,34-6.37,43.52s-7.95,21.07-19.1,28.66c-9.45,6.43-16.92,7.49-14.43,19.59,2.6,12.62,2.43,16.07,9.12,46.23,2.37,10.72,6.57,29.64,2.12,52-1.63,8.18-9,40.1-23.35,63.69a32.67,32.67,0,0,0-5.31,15.93c0,1-.37,13.42,8.49,22.29,5.37,5.37,11.44,6.78,18,8.49,35.84,9.3,34.82,13.94,54.14,15.92,14.65,1.51,21.13-.55,32.91-1.06,26.75-1.14,41.06,7.48,84.92,24.42,58.86,22.73,56.28,16.15,93.42,32.91A504.85,504.85,0,0,1,668,499.69"
      transform="translate(414.97 416.55)"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="10"
      strokeDasharray="2886"
      strokeDashoffset={anim * 2886}
    />
    <path
      d="M-115.06,64.62c4.82-9.57,14.51-25,32.41-36.33,13-8.17,24.24-10.4,41.23-13.74,20.68-4.08,33.73-6.65,47.13,0,9.59,4.75,15,12.09,20.62,19.63,9.88,13.33,13.45,26,15.71,34.37,4.42,16.29,4.23,28.66,3.93,49.09C45.69,136.1,45.55,145.33,42,153c-4.8,10.46-10.61,13.56-14.73,27.49-3.91,13.23-1.13,18.75,0,20.62,2,3.25,5.15,5.67,10.8,7.85,12.61,4.86,24.34,3.44,41.24,1,15.17-2.2,27.88-4.28,37.31-5.89"
      transform="translate(414.97 416.55)"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="10"
      strokeDasharray="441"
      strokeDashoffset={anim * 441}
    />
    <path
      d="M240.37,9.64A68.36,68.36,0,0,0,215.82-7c-14-5.51-26-4.72-36.32-3.93-11.58.89-25.79,2-40.26,11-20.09,12.51-27.87,32.3-30.44,40.07"
      transform="translate(414.97 416.55)"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="10"
      strokeDasharray="160"
      strokeDashoffset={anim * 160}
    />
    <path
      d="M6.69,284.55c17.69,11.31,45.8,24.84,80.51,24.55,17.79-.15,31.53-3.89,46.15-7.86,31.18-8.47,65.33-23.88,63.82-29.45-1.17-4.31-23-.43-65.78,2.94-38.47,3-70.3,3.8-92.3,3.93"
      transform="translate(414.97 416.55)"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="10"
      strokeDasharray="373"
      strokeDashoffset={anim * 373}
    />
  </svg>
);

const Layer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
`;

const Container = styled.div`
  position: relative;
`;

const Image = styled(Layer)`
  background-image: url(${p => p.image});
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  transition: opacity 500ms ease-in-out;
`;
const Contour = styled(ProfileContour)`
  position: absolute;
  transition: opacity 500ms ease-in-out;
  color: #ff5a46;
`;

const Profile = ({ inViewport, className, style, scrollRef }) => (
  <Motion
    defaultStyle={{ x: 1 }}
    style={{
      x: spring(inViewport ? 0 : 1, {
        stiffness: 5,
        damping: 5,
      }),
    }}
  >
    {({ x }) => (
      <Container className={className} style={style}>
        <Image
          ref={scrollRef}
          image={require('./profile.jpg')}
          style={{ opacity: x < 0.1 ? 1 : 0 }}
        />
        <Contour anim={x} style={{ opacity: x < 0.1 ? 0 : 1 }} />
      </Container>
    )}
  </Motion>
);

export default asHoc(WithScroll)(Profile);
