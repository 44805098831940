/**
 * Basic theme
 */

const palette = {
  primary: {
    contrast: 'white',
    main: 'linear-gradient(to right, #4568dc, #b06ab3)',
  },
  secondary: {
    contrast: 'white',
    main: '#242424',
    dark: '#181818',
  },
};

const baseSize = 18;

const typography = {
  baseFontSize: `${baseSize}px`,
  googleFonts: [
    {
      name: 'Roboto',
      styles: ['700', '400', '300'],
    },
  ],
  bodyFontFamily: ['Roboto', '-apple-system', 'sans-serif'],
  scaleRatio: 2.25,
  bodyWeight: 400,
  headerWeight: 500,
  boldWeight: 'bold',
};

const zIndex = {
  header: 10,
  overlay: 15,
  drawer: 20,
  headerText: 25,
};

export default {
  palette,
  typography,
  zIndex,
  size: size => `${size * baseSize}px`,
};

export const colors = {
  darkGrey: '#444',
  grey: '#333',
  oceanBlue: '#4E8E96',
  oceanGreen: '#5CAB80',
};
