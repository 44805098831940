class TimeBlock {
  constructor({
    onStart,
    onEnd,
    duration = 0,
    prevBlock = null,
    nextBlock = null,
  }) {
    this.duration = this.remaining = duration;
    this.nextBlock = nextBlock;
    this.prevBlock = prevBlock;
    this.onStart = onStart;
    this.onEnd = onEnd;
    this._done = this._done.bind(this);
    this.status = 'pause';
  }

  play() {
    if (this.status === 'play') {
      return;
    }
    this.status = 'play';
    if (this.onStart) {
      this.onStart();
    }
    if (this.duration <= 0) {
      this._done();
      return;
    }
    this.startTime = new Date();
    clearTimeout(this.timer);
    this.timer = setTimeout(this._done, this.remaining);
  }

  pause() {
    if (this.status === 'pause') {
      return;
    }
    this.status = 'pause';
    clearTimeout(this.timer);
    this.remaining -= new Date() - this.startTime;
  }

  _done() {
    this.reset();
    if (this.onEnd) {
      this.onEnd();
    }
    if (this.nextBlock) {
      this.nextBlock.play();
    }
  }

  reset() {
    this.status = 'pause';
    clearTimeout(this.timer);
    this.remaining = this.duration;
  }
}

export default class Timeline {
  constructor(timeline, duration, name = '') {
    this._timeline = timeline;
    this._duration = duration;
    this.name = name;
    this._currentBlock = 0;
    this._blocks = this._createTimeBlocks();
  }

  update(timeline, duration = null, name = null) {
    this.reset();
    if (duration) this.duration = duration;
    if (name) this.name = name;
    this.timeline = timeline;
    this._createTimeBlocks();
  }

  _createTimeBlocks() {
    const blocks = {};
    const entries = Object.entries(this._timeline);
    for (let i = 0; i < entries.length; i++) {
      const prevBlock = blocks[i - 1];
      blocks[i] = new TimeBlock({
        onStart: () => {
          this._currentBlock = i;
        },
        onEnd: () => {
          entries[i][1]();
        },
        prevBlock,
      });
      if (prevBlock) {
        prevBlock.nextBlock = blocks[i];
      }

      if (i > 0) {
        blocks[i].duration =
          ((entries[i][0] - entries[i - 1][0]) * this._duration) / 100;
      }
    }
    return blocks;
  }

  play() {
    this._blocks[this._currentBlock].play();
  }

  pause() {
    this._blocks[this._currentBlock].pause();
  }

  reset() {
    this._blocks[this._currentBlock].reset();
    this._currentBlock = 0;
  }
}
