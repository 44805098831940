import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import {
  FaLinkedin,
  FaGithub,
  FaGooglePlus,
  FaFacebook,
  FaHeart,
} from 'react-icons/fa';
import Container from '../Container';
import Logo from '../../icons/Logo';

import { mq } from '../../utils/mq';

const Footer = styled.footer`
  color: white;
  background: #333;
  p,
  span {
    font-size: 1rem;
  }
  ${mq.small} {
    font-size: inherit;
  }
`;

const Upper = styled(Container)`
  padding-bottom: 2rem;
  ${mq.small} {
    display: flex;
    flex-direction: row;
  }
`;

const Lower = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  background: #222;
`;

const Section = styled.div`
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 4rem;
  nav {
    display: flex;
    flex-direction: column;
    a {
      color: white;
      text-decoration: none;
      line-height: 2rem;
    }
  }
  p {
    max-width: 200px;
  }
`;

const CircleLink = styled.a`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222;
  margin: 0.5rem;
  color: white;
  border-radius: 100%;
  font-size: 1.5rem;
  transition: background 200ms ease-in-out;
  &:hover {
    background: #232323;
  }
`;
const SocialNav = styled.div`
  display: flex;
  flex-direction: row;
`;
const FooterLogo = styled(Logo)`
  width: 100px;
  ${mq.small} {
    width: 200px;
  }
`;
export default props => (
  <Footer>
    <Upper {...props}>
      <Section>
        <h3>Let&apos;s keep in touch</h3>
        <SocialNav>
          <CircleLink href="https://www.linkedin.com/in/cjkihl" alt="Linked In">
            <FaLinkedin />
          </CircleLink>
          <CircleLink href="https://github.com/caki0915" alt="Github">
            <FaGithub />
          </CircleLink>
        </SocialNav>
        <SocialNav>
          <CircleLink
            href="https://plus.google.com/+CarlJohanKihl"
            alt="Google Plus"
          >
            <FaGooglePlus />
          </CircleLink>
          <CircleLink
            href="https://www.facebook.com/carljohan.kihl"
            alt="Facebook"
          >
            <FaFacebook />
          </CircleLink>
        </SocialNav>
      </Section>
      <Section
        css={css`
          ${mq.small} {
            display: none;
          }
          ${mq.large} {
            display: inherit;
          }
        `}
      >
        <FooterLogo visible />
      </Section>
      <Section>
        <p>
          This site is crunched together in <b>React</b>, powered by a bunch of
          my favorite tools an liberaries: <b>Gatsby.js</b>, <b>Redux</b>,{' '}
          <b>Emotion</b>, <b>THREE.js</b> and <b>react-motion</b>
        </p>
      </Section>
    </Upper>
    <Lower>
      <span>Handcrafted with &nbsp;</span>
      <FaHeart />
      <span>&nbsp; by me 2019</span>
    </Lower>
  </Footer>
);
