import { css } from '@emotion/core';
import { mq } from '../../utils/mq';

export default css`
  document,
  body {
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    color: #222;
    scroll-behavior: smooth;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    text-align: center;
  }
  h1 {
    font-weight: 900;
    font-size: 3rem;
    line-height: 1;
    margin: 0;
    color: #ff5a46;
    ${mq.small} {
      font-size: 6rem;
    }
  }
  figure {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
`;
