import React from 'react';
import styled from '@emotion/styled';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaShoppingCart, FaMobile, FaDesktop } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import { mq } from '../utils/mq';
import Container from './Container';
import Wave from '../images/Wave';
import ThreeD from '../icons/ThreeD';
import Cloud from '../icons/Cloud';

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: stretch;
  background: #ff5a46;
  color: white;
  padding: 2rem 0;
`;

const Items = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Item = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  p {
    padding: 2rem;
    margin: 0;
    max-width: 300px;
  }
  svg {
    height: 3rem;
    font-size: 3rem;
    color: white;
  }
  ${mq.small} {
    flex-basis: 50%;
  }
  ${mq.medium} {
    flex-basis: 33.33%;
  }
`;
const Header = styled.h1`
  color: white;
`;

const TopEdge = styled(Wave)`
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-99%);
  fill: #ff5a46;
  ${mq.ie} {
    display: none;
  }
`;
const BottomEdge = styled(Wave)`
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(99%) scale(-1);
  fill: #ff5a46;
  ${mq.ie} {
    display: none;
  }
`;
export default props => (
  <Section {...props}>
    <TopEdge />
    <Header>What I do</Header>
    <Items>
      <ScrollAnimation animateIn="fadeIn">
        <Item>
          <h3>App-development</h3>
          <FaMobile />
          <p>
            I build apps in <b>React-Native</b> for IOS and Android.
          </p>
        </Item>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Item>
          <h3>Web-development</h3>
          <FaDesktop />
          <p>
            I build <b>responsive sites</b>, both backend and frontend using the
            latest technology for smarter web-apps.
          </p>
        </Item>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Item>
          <h3>E-commerce</h3>
          <FaShoppingCart />
          <p>
            With <b>6+ years</b> experience, I advice and develop e-commerce for
            both <b>B2B</b> and <b>B2C</b> sites.
          </p>
        </Item>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Item>
          <h3>Cloud</h3>
          <Cloud />
          <p>
            I&apos;m working in the cloud with both in <b>AWS</b> and{' '}
            <b>Azure</b> and love serverless architectures.
          </p>
        </Item>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Item>
          <h3>Design</h3>
          <MdDashboard />
          <p>
            I love to enhance <b>user satisfaction</b> with{' '}
            <b>smart interactions</b>, animations and design.
          </p>
        </Item>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Item>
          <h3>3D</h3>
          <ThreeD />
          <p>
            Realtime <b>3D</b> visualization for web. From particle effects to{' '}
            <b>AR/VR</b> to optimize CAD-drawings for <b>WebGL</b>.
          </p>
        </Item>
      </ScrollAnimation>
    </Items>
    <BottomEdge />
  </Section>
);
