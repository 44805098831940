import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import Scene from '../components/Scene/Particles';
import Section from '../components/Section';
import Footer from '../components/Footer';
import ReadMoreArrow from '../components/ReadMoreArrow';
import Profile from '../images/Profile';
import Logo from '../icons/Logo';
import IphoneX from '../icons/IphoneX';
import ToolsSection from '../components/ToolsSection';
import { mq } from '../utils/mq';
import scrollTo from '../utils/scroll/scroll-to';
import Features from '../components/Features';
import Layout from '../components/Layout';

const HeaderAnimation = keyframes`
from {
transform: translateX(30px);
opacity: 0;
}
to {
transform: translateX(0);
opacity: 1;
}
`;

const Banner = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq.medium} {
    flex-direction: row;
  }
  color: white;
`;
const Header = styled.header`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: 0;
  padding-left: 0;
  opacity: 0;
  animation: ${HeaderAnimation} 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  text-align: center;
  ${mq.small} {
    padding-left: 2rem;
    text-align: left;
  }
`;
const Signature = styled.p`
  font-size: 2rem;
  font-family: 'Herr Von Muellerhoff', cursive;
  ${mq.small} {
    font-size: 2.5rem;
    position: absolute;
    right: 0;
    margin-top: 1rem;
  }
`;

const Title = styled.h1`
  color: white;
`;

const ReadMore = styled.a`
  font-size: 0.2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
`;

const P = styled.p`
  max-width: 450px;
`;

const BannerLogo = styled(Logo)`
  width: 75px;
  ${mq.small} {
    width: 150px;
  }
`;
const ProfileImage = styled(Profile)`
  width: 250px;
  height: 250px;
  ${mq.small} {
    width: 300px;
    height: 300px;
  }
`;
const PhoneContainer = styled.div`
  transform: scale(0.8);
  ${mq.small} {
    transform: scale(1);
  }
`;

class IndexPage extends React.Component {
  scrollToSectionOne = () => {
    scrollTo({ to: this.sectionOne, offset: -72 });
  };

  render() {
    return (
      <Layout>
        <Scene>
          <Banner>
            <BannerLogo visible />
            <Header>
              <Title>
                Developer
                <br />
                &amp;&nbsp;Designer
              </Title>
              <Signature>Carl-Johan Kihl</Signature>
            </Header>
          </Banner>
          <ReadMore>
            <ReadMoreArrow onClick={this.scrollToSectionOne} />
          </ReadMore>
        </Scene>
        <div id="About-me" data-offset="-72" />
        <Section
          ref={r => (this.sectionOne = r)}
          imageComp={<ProfileImage />}
          reverse
        >
          <h1>
            <span>Hi!</span>
          </h1>
          <P>
            I&apos;m C-J. A <b>fullstack developer</b> and <b>designer</b> from
            Sweden. I can help you build <b>cross-platform apps</b> and{' '}
            <b>3D</b> visualizations.
          </P>
        </Section>
        <Section
          imageComp={
            <PhoneContainer>
              <IphoneX />
            </PhoneContainer>
          }
          style={{ marginBottom: '6rem' }}
        >
          <P>
            With great experience in <b>React, React Native</b> and{' '}
            <b>WebGL.</b> I love to build crispy apps with the latest
            technology.
          </P>
        </Section>
        <div id="My-skills" data-offset="-72" />
        <Features />
        <div id="My-tools" data-offset="72" />
        <ToolsSection />
        <Footer style={{ paddingTop: '6rem' }} />
      </Layout>
    );
  }
}
export default IndexPage;
