/* global document  */
import raf from 'raf';

function move(amount) {
  document.documentElement.scrollTop = amount;
  document.body.parentNode.scrollTop = amount;
  document.body.scrollTop = amount;
}
function position() {
  return (
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop
  );
}

function easeInOutQuad(t, b, c, d) {
  t /= d / 2; // eslint-disable-line
  if (t < 1) {
    return c / 2 * t * t + b;
  }
  t--; //eslint-disable-line
  return -c / 2 * (t * (t - 2) - 1) + b;
}

export default function({ to, offset = 0, duration = 1000 }) {
  if (!document) return Promise.resolve();
  const start = position();
  const type = typeof to;

  if (typeof offset === 'string') {
    offset = parseFloat(offset); //eslint-disable-line
  }
  if (type === 'object') {
    to = to.getBoundingClientRect().top; //eslint-disable-line
  } else if (type === 'string') {
    to = to.replace('#', ''); //eslint-disable-line
    const el = document.getElementById(to);
    if (!el) {
      return Promise.resolve();
    }
    to = el.getBoundingClientRect().top; //eslint-disable-line
  }
  const delta = to - start + offset;
  if (delta === 0) {
    return Promise.resolve();
  }
  let currentTime = 0;
  const increment = 20;
  return new Promise(resolve => {
    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, delta, duration);
      move(val);
      if (currentTime < duration) {
        raf(animateScroll);
      } else {
        resolve();
      }
    };
    animateScroll();
  });
}
