import React from 'react';

export default props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444 506.131">
    <title>3d</title>
    <path
      fill="currentColor"
      d="M264.663,5C259.9,2.248,252.1,2.248,247.337,5L42.663,123.005C37.9,125.752,34,132.5,34,138V374c0,5.5,3.9,12.248,8.663,15l204.674,118.01c4.765,2.747,12.561,2.747,17.326,0L469.337,389c4.765-2.747,8.663-9.5,8.663-15V138c0-5.5-3.9-12.248-8.663-15ZM240,457c0,5.5-3.9,7.751-8.661,5L73.661,371C68.9,368.249,65,361.5,65,356V174c0-5.5,3.9-7.751,8.661-5l157.678,91c4.764,2.75,8.661,9.5,8.661,15Zm24.661-224c-4.764,2.75-12.558,2.75-17.322,0L89.661,142c-4.764-2.75-4.764-7.248,0-10L247.339,41c4.764-2.75,12.558-2.75,17.322,0l157.678,91c4.764,2.75,4.764,7.248,0,10Zm16,229c-4.764,2.75-8.661.5-8.661-5V275c0-5.5,3.9-12.249,8.661-15l157.678-91c4.764-2.75,8.661-.5,8.661,5V356c0,5.5-3.9,12.249-8.661,15Z"
      transform="translate(-34 -2.935)"
    />
  </svg>
);
