import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Motion, spring } from 'react-motion';
import Logo from './Logo';
import Check from './MaterialIcons/Check';
import Timeline from '../utils/timeline';
import Typewriter from '../utils/Typewriter';
import WithScroll from '../utils/scroll/WithScroll';
import asHoc from '../utils/asHoc';

const Frame = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 539" {...props}>
    <path
      fill="#5C5C5C"
      d="M255 268.3V42.9A27.8 27.8 0 0 0 227.5 16h-23c-3.1 0-4.5 1.3-4.8 4.4l-.4 5c-.5 3.5-2.4 6-5.5 7.5-1.6.9-3.4 1-5.3 1H81.2c-5-.1-9-3.5-9.6-8.4-.3-1.7-.4-3.5-.5-5.2-.3-3.1-1.5-4.3-4.6-4.3h-23c-10 .3-17.9 4.8-23.4 13.3C17 34.3 16 40 16 45.7v446.8c.4 2.6.6 5.3 1.4 7.8A27.7 27.7 0 0 0 44 520.1h183c12.1 0 20.7-6 25.9-16.8 1.9-4 2.3-8.4 2.3-12.8V268.3zm12-144c2.9.7 3 .8 3 3.6v53c0 2.9-.1 3-3 3.8v311a41 41 0 0 1-8.5 25.8A40.6 40.6 0 0 1 225 538H44.2A41.6 41.6 0 0 1 2 495.4c-.2-16.5-.1-33-.1-49.6V199.6c-.1-.5-.3-1.1-.7-1.4-1.2-.7-1.3-1.8-1.3-3v-33.6c0-1.2.1-2.3 1.3-3 .4-.3.6-1.1.7-1.7v-4.3c0-1-.1-1.9-1.1-2.7-.7-.5-.9-2-.9-3v-32.8c0-1.4 0-2.6 1.4-3.5.4-.2.6-1 .6-1.5v-15c0-.5-.3-1.2-.8-1.5-1-.8-1.2-1.7-1.2-2.8v-14c0-.8.3-2 .8-2.4 1.3-.8 1.2-1.9 1.2-3V41.7a41 41 0 0 1 13-30A40 40 0 0 1 43.5 0h182A41.7 41.7 0 0 1 267 42.4v81.8z"
    />
    <g fill="#CFD5D9">
      <path
        transform="translate(113 21)"
        d="M0 2.5C0 1.1 1.1 0 2.5 0h39a2.5 2.5 0 0 1 0 5h-39A2.5 2.5 0 0 1 0 2.5z"
      />
      <path
        transform="translate(162 21)"
        d="M2.5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
      />
    </g>
  </svg>
);

const Phone = styled.div`
  position: relative;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
`;
const Bg = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 15px;
  bottom: 18px;
  background: #303030;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
`;
const Field = styled.div`
  display: flex;
  align-items: center;
  color: white;
  border: none;
  background-color: #404040;
  font-size: 12px;
  height: 50px;
  align-self: stretch;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 40px;
  margin-bottom: 20px;
  justify-content: space-between;
`;
const Button = styled.button`
  font-size: 12px;
  padding: 15px;
  width: 60%;
  border-radius: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
  color: white;
  background: #27ae60;
  border: none;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

class IPhone extends React.Component {
  static defaultProps = { width: '270', height: '539' };

  state = {
    showLogo: false,
    showPhone: false,
    writeEmail: false,
    showCheckmark: false,
  };

  inViewPort = false;

  constructor(props) {
    super(props);
    this.showTimeline = new Timeline(
      {
        0: () =>
          this.setState({
            showPhone: false,
            showLogo: false,
            writeEmail: false,
            showCheckmark: false,
          }),
        10: () =>
          this.setState({
            showPhone: true,
          }),
        40: () =>
          this.setState({
            showLogo: true,
            writeEmail: true,
          }),
        100: () =>
          this.setState({
            showCheckmark: true,
          }),
      },
      1000,
      'swipeIn',
    );
    this.hideTimeline = new Timeline(
      {
        0: () =>
          this.setState({
            showPhone: true,
            showLogo: true,
            writeEmail: true,
            showCheckmark: true,
          }),
        100: () =>
          this.setState({
            showPhone: false,
            showLogo: false,
            writeEmail: false,
            showCheckmark: false,
          }),
      },
      500,
      'fadeAway',
    );
  }

  componentWillReceiveProps(nextProps) {
    const { inViewport } = this.props;
    if (nextProps.inViewport && !inViewport) {
      this.hideTimeline.reset();
      this.showTimeline.play();
    } else if (!nextProps.inViewport && inViewport) {
      this.showTimeline.reset();
      this.hideTimeline.play();
    }
  }

  render() {
    const { width, height, scrollRef } = this.props;
    const { showPhone, showCheckmark, showLogo, writeEmail } = this.state;
    return (
      <Motion
        defaultStyle={{ phone: 0, checkmark: 0 }}
        style={{
          phone: spring(showPhone ? 1 : 0),
          checkmark: spring(showCheckmark ? 1 : 0),
        }}
      >
        {({ phone, checkmark }) => (
          <Phone
            width={width}
            height={height}
            style={{
              opacity: phone,
              transform: `translateX(${(1 - phone) * 30}%)`,
            }}
          >
            <Bg>
              <LogoContainer>
                <Logo visible={showLogo} style={{ width: '50%' }} />
              </LogoContainer>
              <Field ref={scrollRef}>
                <Typewriter text="hello@carljohan.me" write={writeEmail} />
                <Check
                  color="#27ae60"
                  style={{ transform: `scale(${checkmark})` }}
                />
              </Field>
              <Field />
              <Button>Login</Button>
            </Bg>
            <Frame
              width={width}
              css={css`
                position: absolute;
                top: 0;
                left: 0;
              `}
            />
          </Phone>
        )}
      </Motion>
    );
  }
}

export default asHoc(WithScroll)(IPhone);
