import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../utils/mq';

const Section = styled.section`
  position: relative;

  ${mq.medium} {
    display: flex;
    flex-direction: ${p => (p.reverse ? 'row-reverse' : 'row')};
    height: 80vh;
  }
  align-items: stretch;
`;

const Content = styled.article`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  ${mq.medium} {
    font-size: 2rem;
  }
`;

const Image = styled.figure`
  background-image: url(${p => p.image});
  background-position: center;
  background-size: cover;
  margin: 0;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageComp = styled.figure`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default React.forwardRef(
  ({ image, imageComp, children, reverse, ...restProps }, ref) => (
    <Section {...restProps} reverse={reverse} ref={ref}>
      <Content reverse={reverse}>{children}</Content>
      {image && <Image image={image} />}
      {imageComp && <ImageComp>{imageComp}</ImageComp>}
    </Section>
  ),
);
