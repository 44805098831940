import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import ScrollAnimation from 'react-animate-on-scroll';
import Nodejs from '../icons/Logos/NodeJs.svg';
import Android from '../icons/Logos/Android.svg';
import Azure from '../icons/Logos/Azure.svg';
import Docker from '../icons/Logos/Docker.svg';
import Ios from '../icons/Logos/IOS.svg';
import Js from '../icons/Logos/JS.svg';
import Ts from '../icons/Logos/TS.svg';
import Csharp from '../icons/Logos/CSharp.png';
import DotNet from '../icons/Logos/DotNet.png';
import ReactJS from '../icons/Logos/React.svg';
import MongoDB from '../icons/Logos/MongoDB.svg';
import CouchDB from '../icons/Logos/CouchDB.svg';
import Aws from '../icons/Logos/Aws.png';
import Git from '../icons/Logos/Git.svg';
import Container from './Container';
import Planets from '../images/Planets';
import ILayer from './Layer';
import { mq } from '../utils/mq';

const PlanetAnimation = keyframes`
to {
  transform: rotate(360deg);
}
`;
const Section = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10rem;
  ${mq.small} {
    padding-top: 6rem;
  }
`;
const Layer = styled(ILayer)`
  overflow: hidden;
`;
const Tools = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
`;
const Img = styled.img`
  width: 100px;
  margin: 1rem;
`;
const WideImg = styled(Img)`
  width: 160px;
`;
const Title = styled.h1`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const PlanetBg = styled(ILayer)`
  opacity: 0.1;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Planet = styled(Planets)`
  animation: ${PlanetAnimation} 40s linear infinite;
  width: 100%;
  max-width: 1000px;
`;

export default props => (
  <Section {...props}>
    <Layer>
      <PlanetBg
        style={{
          transform: 'scale(1.4)',
        }}
      >
        <Planet
          style={{
            animationDuration: '80s',
            animationDirection: 'reverse',
          }}
        />
      </PlanetBg>
      <PlanetBg style={{ transform: 'scale(1)' }}>
        <Planet />
      </PlanetBg>
    </Layer>
    <Title>My Toolbox</Title>
    <ScrollAnimation animateIn="fadeIn">
      <Tools>
        <Img src={Js} alt="Js" />
        <Img src={Ts} alt="Ts" />
        <Img src={Csharp} alt="C-Sharp" />
        <Img src={DotNet} alt=".NET" />
        <Img src={ReactJS} alt="React JS" />
        <WideImg src={Nodejs} alt="Node JS" />
        <WideImg src={Aws} alt="Amazon Web services" />
        <Img src={Android} alt="Android" />
        <Img src={Ios} alt="IOS" />
        <WideImg src={Git} alt="Git" />
        <WideImg src={Docker} alt="Docker" />
        <WideImg src={Azure} alt="Azure" />
        <WideImg src={CouchDB} alt="CouchDB" />
        <WideImg src={MongoDB} alt="MongoDB" />
      </Tools>
    </ScrollAnimation>
  </Section>
);
