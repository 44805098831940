import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import 'animate.css/animate.min.css';
import { ThemeProvider } from 'emotion-theming';
import Header from '../Header';
import Drawer from '../Drawer';
import theme from '../../utils/theme';
import { toggleDrawer as toggleDrawerAction } from '../../state/app';
import globalStyles from './globalStyles';

const Container = styled.main`
  width: 100vw;
  overflow-x: hidden;
`;

const Content = styled.section`
  transition: transform 0.3s ease-in-out;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: ${p => p.theme.zIndex.overlay};
  top: 0;
  left: 0;
  background: black;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.3s ease-in-out;
  opacity: ${p => (p.isDrawerOpen ? 0.5 : 0)};
  pointer-events: ${p => (p.isDrawerOpen ? 'all' : 'none')};
`;

const TemplateWrapper = ({ children, isDrawerOpen, toggleDrawer }) => (
  <ThemeProvider theme={theme}>
    <div>
      <Global styles={globalStyles} />
      <Helmet
        title="Car-Johan - Developer"
        meta={[
          { name: 'description', content: 'Fullstack developer from Sweden' },
          {
            name: 'keywords',
            content:
              'developer, fullstack, cross-platform, react, react-native, ethereum, blockchain, mobile, webdesign, ux',
          },
        ]}
      >
        <link rel="icon" href="/icons/icon-48x48.png" />
        <link
          href="https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff|Roboto:300,700"
          rel="stylesheet"
        />
      </Helmet>
      <Container>
        <Content isDrawerOpen={isDrawerOpen}>{children}</Content>
      </Container>
      <Overlay
        isDrawerOpen={isDrawerOpen}
        onClick={() => toggleDrawer(false)}
      />
      <Drawer />
      <Header />
    </div>
  </ThemeProvider>
);

export default connect(
  state => ({ isDrawerOpen: state.app.isDrawerOpen }),
  dispatch => ({ toggleDrawer: open => dispatch(toggleDrawerAction(open)) }),
)(TemplateWrapper);
