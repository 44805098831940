import React from 'react';
import { StaggeredMotion, spring } from 'react-motion';
import { colors } from '../../utils/theme';

export default ({ className, style, visible }) => (
  <StaggeredMotion
    defaultStyles={[{ x: 0 }, { x: 0 }, { x: 0 }]}
    styles={prevInterpolatedStyles =>
      prevInterpolatedStyles.map((_, i) =>
        i === 0
          ? {
              x: spring(visible ? 1 : 0, {
                stiffness: 100,
                damping: 16,
              }),
            }
          : {
              x: spring(prevInterpolatedStyles[i - 1].x >= 0.9 ? 1 : 0, {
                stiffness: 70,
                damping: 20,
              }),
            },
      )
    }
  >
    {s => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 54 54"
        className={className}
        style={{
          transform: `rotate(${s[0].x}turn) scale(${s[0].x})`,
          ...style,
        }}
      >
        <path
          fill="rgba(0,0,0,0.3)"
          d="M54 27c0 14.9117-12.0883 27-27 27S0 41.9117 0 27 12.0883 0 27 0s27 12.0883 27 27z"
          style={{ opacity: s[2].x }}
        />
        <g>
          <g>
            <use
              fill={colors.oceanBlue}
              transform="translate(11 7)"
              xlinkHref="#LogoHexagon"
            />
          </g>
          <g
            style={{
              transform: `translateY(${-4 * (1 - s[2].x)}px)`,
            }}
          >
            <use
              fill={colors.oceanGreen}
              transform="matrix(-1 0 0 1 43 11)"
              xlinkHref="#LogoHexagon"
            />
          </g>
          <g style={{ opacity: s[2].x }}>
            <path
              fill="#ABABAB"
              transform="translate(27 22)"
              d="M0 7l12-7v14L0 21V7z"
            />
            <path
              fill="#CFCFCF"
              transform="translate(15 15)"
              d="M12 0l12 7-12 7L0 7l12-7z"
            />
          </g>
        </g>
        <defs>
          <path
            id="LogoHexagon"
            fillRule="evenodd"
            d="M16 32v4L0 27V9l16-9v4L4 11v14l12 7z"
          />
        </defs>
      </svg>
    )}
  </StaggeredMotion>
);
