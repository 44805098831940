import React from 'react';

export default (
  RenderPropsComponent,
  renderPropsConfig = () => null,
) => WrappedComponent => props => (
  <RenderPropsComponent {...renderPropsConfig(props)}>
    {scrollProps => <WrappedComponent {...scrollProps} {...props} />}
  </RenderPropsComponent>
);
