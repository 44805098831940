import React from 'react';

export default props => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <circle
      cx="256"
      cy="256"
      r="237"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="5"
      opacity="0.5"
    />
    <circle cx="91" cy="84" r="24" />
    <circle cx="493" cy="256" r="17" />
    <circle cx="91" cy="429" r="21" />
  </svg>
);
