import React from 'react';

export default class Typewriter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  write() {
    const { state, props } = this;
    if (state.text.length === props.text.length) return;
    this.timeout = setTimeout(() => {
      const text = props.text.slice(0, state.text.length + 1);
      this.setState({ text });
      this.write();
    }, 100);
  }

  remove() {
    const { state, props } = this;
    if (state.text.length === 0) return;
    this.timeout = setTimeout(() => {
      const text = props.text.slice(0, state.text.length - 1);
      this.setState({ text });
      this.remove();
    }, 100);
  }

  componentWillReceiveProps(nextProps) {
    const { props } = this;
    if (nextProps.write && !props.write) {
      clearTimeout(this.timeout);
      this.write();
    } else if (!nextProps.write && props.write) {
      clearTimeout(this.timeout);
      this.remove();
    }
  }

  render() {
    const { text } = this.state;
    return <span>{text}</span>;
  }
}
