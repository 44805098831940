import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Hamburger from './Hamburger';
import Logo from '../../icons/Logo';
import { toggleDrawer as toggleDrawerAction } from '../../state/app';
import { showSmall, hideSmall } from '../../utils/mq';
import asHoc from '../../utils/asHoc';
import WithScroll from '../../utils/scroll/WithScroll';
import window from '../../utils/window';

const MenuIcon = styled.a`
  height: ${p => p.theme.size(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${p => p.theme.size(1)};
  transition: right 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
`;

const Navbar = styled.div`
  height: ${p => p.theme.size(4)};
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: center;
  z-index: ${p => p.theme.zIndex.header};
  top: 0;
  left: 0;
  padding-left: ${p => p.theme.size(0.5)};
  background: #282828;
  transition: opacity 1s ease-in-out;
`;

const Top = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${p => p.theme.size(4)};
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${p => p.theme.zIndex.drawer + 1};
`;

const Title = styled(Link)`
  color: ${p => p.theme.palette.primary.contrast};
  padding-right: ${p => p.theme.size(1)};
  text-decoration: none;
  font-size: ${p => p.theme.size(2)};
  transition: opacity 1s ease-in-out;
  font-family: 'Herr Von Muellerhoff', cursive;
`;

const Header = ({ isDrawerOpen, toggleDrawer, viewport }) => {
  const showHeader =
    (window && window.location.pathname !== '/') ||
    (viewport.y > 300 && !isDrawerOpen);

  return (
    <div>
      <Top>
        <MenuIcon
          isDrawerOpen={isDrawerOpen}
          href="#"
          onClick={e => {
            e.preventDefault();
            toggleDrawer(!isDrawerOpen);
          }}
        >
          <Hamburger />
        </MenuIcon>
      </Top>
      <Navbar
        isDrawerOpen={isDrawerOpen}
        style={{ opacity: showHeader ? 1 : 0 }}
      >
        <Title to="/">
          <span css={showSmall}>C-J</span>
          <span css={hideSmall}>Carl-Johan Kihl</span>
        </Title>
        <Logo style={{ width: '50px' }} visible={showHeader} />
      </Navbar>
    </div>
  );
};

export default compose(
  connect(
    state => ({ isDrawerOpen: state.app.isDrawerOpen }),
    dispatch => ({ toggleDrawer: open => dispatch(toggleDrawerAction(open)) }),
  ),
  asHoc(WithScroll),
)(Header);
